/// <reference path="../../src/global.d.ts" />

import { create } from '@storybook/theming/create';
import { getMuiPalette } from '../../src/styles/palette';
import { Theme } from '@mui/material';

export const createTheme = brandColor => {
	const palette = getMuiPalette(brandColor) as Theme['palette'];
	const secondary = palette.secondary;
	const primary = palette.primary;

	return create({
		base: 'light',

		colorPrimary: primary.main,
		colorSecondary: primary.lighter,

		appBg: palette.background.paper,
		appContentBg: palette.common.white,
		appBorderColor: secondary.light,
		appBorderRadius: 2,

		fontCode: 'monospace',

		textColor: palette.text.primary,
		textInverseColor: palette.background.default,

		barBg: secondary.main,
		barTextColor: secondary.contrastText,
		barSelectedColor: primary.lighter,

		inputBg: palette.common.white,
		inputBorder: secondary.light,
		inputTextColor: secondary.contrastText,
		inputBorderRadius: 2,

		brandTitle: 'Fabric Book',
	});
};
