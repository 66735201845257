import { ifFeature } from '@bamboohr/utils/lib/feature';

export const getChartColors = (): string[] => {
	const jadeChartColors = [
		'#759e00',
		'#e8454d',
		'#f96422',
		'#6f90e8',
		'#d05489',
		'#3aa837',
		'#b88f00',
		'#0fa698',
		'#9a6dc8',
		'#1b8ab4',
		'#6a6a6a',
	];
	const encoreChartColors = [
		'#7E9D2E',
		'#D65253',
		'#E76E38',
		'#768FE2',
		'#C15C88',
		'#5BA647',
		'#B2912F',
		'#4CA498',
		'#936FC2',
		'#4288B0',
	];
	return ifFeature('encore', encoreChartColors, jadeChartColors);
};
