{
	"colors": {
		"info": "#0772b3",
		"infoDark": "#004279",
		"infoLight": "#e6f5fe",
		"danger": "#c20b0b",
		"dangerDark": "#890000",
		"dangerLight": "#fee7e7",
		"attention": "#bd5800",
		"attentionDark": "#872B00",
		"attentionLight": "#fff1e5",
		"success": "#0e840d",
		"successDark": "#005600",
		"successLight": "#e8fde8",
		"action": "#ffe30a",
		"white": "#fff",
		"gray1": "#f4f4f4",
		"gray2": "#ebebeb",
		"gray3": "#e0e0e0",
		"gray4": "#ccc",
		"gray5": "#adadad",
		"gray6": "#999",
		"gray7": "#686868",
		"gray8": "#555",
		"gray9": "#444",
		"gray10": "#222"
	}
}
