import { PaletteOptions } from '@mui/material/styles/createPalette';
import jsonThemeColors from '../../definitions/json/theme-colors.json';
import jsonColors from '../../definitions/json/colors.json';
import { getChartColors } from './getChartColors';

export const getMuiPalette = (brandColor?: string): PaletteOptions => {
	const { themeColors } = jsonThemeColors;
	const { colors } = jsonColors;
	const selectedColors = themeColors.find(theme => theme.name === brandColor) || themeColors[0];
	const gray = {
		// 50 gives Encore palette better backwards-compatibility
		50: colors.gray1,
		100: colors.gray1,
		200: colors.gray2,
		300: colors.gray3,
		400: colors.gray4,
		500: colors.gray5,
		600: colors.gray6,
		700: colors.gray7,
		800: colors.gray8,
		900: colors.gray9,
		1000: colors.gray10,
	};

	return {
		mode: 'light',
		primary: {
			main: selectedColors.base,
			/*
			 * We do not support `primary.dark`, but MUI uses it under the hood in some of our
			 * components, so it should still match our brand colors.
			 */
			dark: selectedColors.light,
			light: selectedColors.light,
			lighter: selectedColors.lighter,
			lightest: selectedColors.lightest,
			contrastText: colors.white,
		},
		secondary: {
			main: colors.gray2,
			dark: colors.gray6,
			light: colors.gray3,
			contrastText: colors.gray8,
		},
		action: {
			active: colors.info,
			highlight: colors.action,
		},
		background: {
			default: colors.white,
			paper: colors.gray1,
		},
		text: {
			primary: colors.gray10,
			secondary: colors.gray8,
		},
		error: {
			main: colors.danger,
			dark: colors.dangerDark,
			light: colors.dangerLight,
			contrastText: colors.white,
		},
		warning: {
			main: colors.attention,
			dark: colors.attentionDark,
			light: colors.attentionLight,
			contrastText: colors.white,
		},
		success: {
			main: colors.success,
			dark: colors.successDark,
			light: colors.successLight,
			contrastText: colors.white,
		},
		info: {
			main: colors.info,
			dark: colors.infoDark,
			light: colors.infoLight,
			contrastText: colors.white,
		},
		common: {
			black: '#000',
			white: '#FFF',
		},
		charts: getChartColors(),
		grey: gray,
		gray,
		// below props are only used in Encore but are required to satisfy the Palette type def
		discovery: {},
		link: {},
	};
};
