{
	"themeColors": [
		{ "name": "lime1", "base": "#527a00", "light": "#6a950a", "lighter": "#82af13", "lightest": "#c0e94f" },
		{ "name": "lime2", "base": "#456e00", "light": "#5e8500", "lighter": "#769c00", "lightest": "#a8cd42" },
		{ "name": "lime3", "base": "#365900", "light": "#4e7000", "lighter": "#658600", "lightest": "#96b63d" },
		{ "name": "lime4", "base": "#2b4900", "light": "#425f01", "lighter": "#587501", "lightest": "#88a43a" },
		{ "name": "green1", "base": "#188124", "light": "#39a33e", "lighter": "#59c457", "lightest": "#8df886" },
		{ "name": "green2", "base": "#007400", "light": "#1e901c", "lighter": "#3cab38", "lightest": "#73de67" },
		{ "name": "green3", "base": "#005d00", "light": "#187416", "lighter": "#308b2c", "lightest": "#64bc59" },
		{ "name": "green4", "base": "#004900", "light": "#056004", "lighter": "#0a7607", "lightest": "#4ba63c" },
		{ "name": "teal1", "base": "#017971", "light": "#289990", "lighter": "#4eb8af", "lightest": "#83ebe1" },
		{ "name": "teal2", "base": "#006f67", "light": "#1c877e", "lighter": "#389e95", "lightest": "#6ecfc5" },
		{ "name": "teal3", "base": "#005d56", "light": "#14746c", "lighter": "#288b82", "lightest": "#5fbbb1" },
		{ "name": "teal4", "base": "#004c45", "light": "#0b635b", "lighter": "#167970", "lightest": "#50a89e" },
		{ "name": "blue1", "base": "#0878a1", "light": "#2f94bf", "lighter": "#56afdd", "lightest": "#8ce1ff" },
		{ "name": "blue2", "base": "#00658e", "light": "#107ca6", "lighter": "#2093be", "lightest": "#63c3f1" },
		{ "name": "blue3", "base": "#00558b", "light": "#0f6ba3", "lighter": "#1d81bb", "lightest": "#60b0ee" },
		{ "name": "blue4", "base": "#003e63", "light": "#00547a", "lighter": "#006991", "lightest": "#4c97c2" },
		{ "name": "violet1", "base": "#4968a9", "light": "#627fc2", "lighter": "#7b96db", "lightest": "#adc6ff" },
		{ "name": "violet2", "base": "#2f579f", "light": "#4a6db8", "lighter": "#6583d1", "lightest": "#98b2ff" },
		{ "name": "violet3", "base": "#1e488d", "light": "#3a5ea6", "lighter": "#5673be", "lightest": "#89a1f1" },
		{ "name": "violet4", "base": "#05397b", "light": "#264e93", "lighter": "#4763ab", "lightest": "#7990dd" },
		{ "name": "purple1", "base": "#8057a8", "light": "#996ec1", "lighter": "#b185da", "lightest": "#e4b5ff" },
		{ "name": "purple2", "base": "#6a4197", "light": "#8257b0", "lighter": "#9a6dc8", "lightest": "#cc9cfb" },
		{ "name": "purple3", "base": "#582374", "light": "#70398c", "lighter": "#874fa3", "lightest": "#b87dd5" },
		{ "name": "purple4", "base": "#44195b", "light": "#5b2e72", "lighter": "#714388", "lightest": "#a16fb8" },
		{ "name": "pink1", "base": "#b14b79", "light": "#cb6391", "lighter": "#e57ba8", "lightest": "#ffacd9" },
		{ "name": "pink2", "base": "#9b1f5c", "light": "#b63a73", "lighter": "#d05489", "lightest": "#ff85b9" },
		{ "name": "pink3", "base": "#9e0057", "light": "#b91a6e", "lighter": "#d43384", "lightest": "#ff6ab4" },
		{ "name": "pink4", "base": "#70003f", "light": "#8a1355", "lighter": "#a3256a", "lightest": "#d85998" },
		{ "name": "red1", "base": "#bc363d", "light": "#d84f53", "lighter": "#f46868", "lightest": "#ff9a96" },
		{ "name": "red2", "base": "#af0025", "light": "#cc2339", "lighter": "#e8454d", "lightest": "#ff7979" },
		{ "name": "red3", "base": "#99000a", "light": "#b6151e", "lighter": "#d22931", "lightest": "#ff615b" },
		{ "name": "red4", "base": "#7f0000", "light": "#9b0e12", "lighter": "#b71b23", "lightest": "#f0554c" },
		{ "name": "orange1", "base": "#ba4e1c", "light": "#db6733", "lighter": "#fb7f49", "lightest": "#ffb077" },
		{ "name": "orange2", "base": "#bf3100", "light": "#dc4b11", "lighter": "#f96422", "lightest": "#ff9651" },
		{ "name": "orange3", "base": "#9c2100", "light": "#b93a0d", "lighter": "#d55319", "lightest": "#ff8347" },
		{ "name": "orange4", "base": "#811200", "light": "#9c2c04", "lighter": "#b74507", "lightest": "#f07438" },
		{ "name": "bronze1", "base": "#a25a00", "light": "#ca7804", "lighter": "#f19508", "lightest": "#ffc649" },
		{ "name": "bronze2", "base": "#ae4f00", "light": "#cb6600", "lighter": "#e77d00", "lightest": "#ffad41" },
		{ "name": "bronze3", "base": "#8e3f00", "light": "#aa5500", "lighter": "#c56b00", "lightest": "#fd9a3c" },
		{ "name": "bronze4", "base": "#5a330c", "light": "#724820", "lighter": "#8a5d34", "lightest": "#bc8a5f" },
		{ "name": "brown1", "base": "#8f6900", "light": "#ba8b00", "lighter": "#e4ac00", "lightest": "#ffdd4a" },
		{ "name": "brown2", "base": "#7f5b00", "light": "#a87c04", "lighter": "#d09d08", "lightest": "#ffce49" },
		{ "name": "brown3", "base": "#655116", "light": "#7d672c", "lighter": "#957d41", "lightest": "#c7ac6d" },
		{ "name": "brown4", "base": "#4e3f11", "light": "#655426", "lighter": "#7c693a", "lightest": "#ad9765" },
		{ "name": "gray1", "base": "#585858", "light": "#6f6f6f", "lighter": "#858585", "lightest": "#b5b5b5" },
		{ "name": "gray2", "base": "#383838", "light": "#4d4d4d", "lighter": "#626262", "lightest": "#8f8f8f" },
		{ "name": "cyan1", "base": "#045F73", "light": "#067E99", "lighter": "#067E99", "lightest": "#E6F5F9" },
		{ "name": "cyan2", "base": "#033F4C", "light": "#045F73", "lighter": "#045F73", "lightest": "#E6F5F9" },
		{ "name": "charcoal1", "base": "#474443", "light": "#5F5B5A", "lighter": "#5F5B5A", "lightest": "#F1F1F1" },
		{ "name": "charcoal2", "base": "#302E2D", "light": "#474443", "lighter": "#474443", "lightest": "#F1F1F1" }
	]
}
